<template>
  <el-container class="camadd">
    <el-header class="box add bb">
       <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>监管主体</el-breadcrumb-item>
         <el-breadcrumb-item >监管管理</el-breadcrumb-item>
       <el-breadcrumb-item @click="$router.back()">主体管理</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.company_tag_id">编辑主体类型</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增主体类型</el-breadcrumb-item>
    </el-breadcrumb>
      <div>
       
        <el-button class="back_btn"
                   size="medium"
                   plain
                   @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="pt0">
      <el-form  ref="form" :model="form" class="demo-ruleForm">
              
         <el-row class="box mt20  f14">
       <el-col :span="11" class="" >
        <el-form-item  label="监管类型："  class="w" label-width="28%" size="medium" >
                <el-input disabled v-model="company_type_name" class="input-with-select"
                    size="medium"
                    clearable></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="clear" >
        <el-form-item label="主体类型名称：" size="medium"  class="w" label-width="28%"
                      prop='company_tag_name'
                      :rules="[{required: true,message: '请输入主体类型名称', trigger: 'blur' }]">
          <el-input v-model="form.company_tag_name"
                    size="medium"
                    clearable></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl" :offset="1">
         <el-button class size="medium" type="primary" @click="onSubmit('form')">提交</el-button>
        </el-col>
                </el-row>
        
      
      </el-form>

    
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import {session} from '../../../util/util'
export default {
  data () {
    return {
      labelPosition: "right",
      dialogImageUrl: '',
      typetOptions:[],
      company_type_name:'',
      form:{
          company_type_id:'',//监管分类ID 更新操作必传,新增不需要传
          company_tag_name:'',
          company_tag_id:''
      }
    };
  },
  filters: {},
  created () {
     this.form.company_type_id =session.get('params1').id?String(session.get('params1').id):'';
       this.form.company_tag_id =session.get('params1').id1?String(session.get('params1').id1):'';
       this.getType()
        if(this.form.company_tag_id){ this.getDate()}//编辑获取回显信息
   },
  methods: {
      getType(){
           var that=this;
          axios.get('/pc/company-type/all').then((v) => { 
              var obj = {}
      obj = v.data.company_type_list.find(item=>{
        return item.company_type_id == this.form.company_type_id;
      })
       this.company_type_name=obj.company_type_name
      })
      },
      getDate(){
          axios.get("/pc/company-tag/single",{company_tag_id:String(this.form.company_tag_id)}).then(v => {
           this.form.company_tag_name=v.data.company_tag.company_tag_name
          });
      },
        onSubmit (form) {
          
      this.$refs[form].validate((valid) => {
        if (valid) {
          axios.get("/pc/company-tag/exist", this.form).then(v => {//验证查询监管分类是否存在
           if(this.form.company_tag_id){
                
               axios.put("/pc/company-tag/update",this.form).then(response => {
              this.$router.back();
            })}else{
              axios.post("/pc/company-tag/save",this.form).then(response => {
              this.$router.back();
            })
           }
           
          });
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
      })
    },
    exist (sensor_number) {
      if (sensor_number != null && sensor_number.length > 0) {
        axios.get("/api/sensor/exist", { sensor_number: sensor_number }).then(v => {
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.camadd {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image {
    width: 160px;
    height: 160px;
    margin-right: 10px;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .patroladd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  .form-container {
    margin-top: 20px;
    padding-right: 35px;
    /deep/ .el-form-item__content {
      width: 50%;
    }
  }
  /deep/ .el-radio__label {
    display: none !important;
  }
  /deep/ .el-dialog__body {
    padding-top: 5px;
    padding-bottom: 10px;
  }
  /deep/ .el-input.is-disabled .el-input__inner {
    background-color: #ffffff;
    border-color: #e4e7ed;
    color: #606266;
    cursor: not-allowed;
  }
  /deep/ .el-dialog {
    width: 60% !important;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /* .el-form-item--medium .el-form-item__content{width: 80%} */
}
</style>
